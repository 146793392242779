import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import AsmtTable from "../Asmt/Main/AsmtTable";
import AsmtStatus from "./AsmtStatus";
import PartStatus from "./PartStatus";
import { useRecoilState } from "recoil";
import { yearState } from "../../system/atoms";
import CloseIcon from "@mui/icons-material/Close";
import { CustomText } from "../../styles/CustomText";

function MainPage() {
  const [year, setYear] = useRecoilState(yearState);
  const [state, setState] = useState("");
  const changeYear = (args: any) => {
    setYear(args.target.value);
  };
  const [open, setOpen] = useState(true);

  const handleHideForDay = () => {
    const tomorrow = new Date();
    tomorrow.setHours(tomorrow.getHours() + 24);
    localStorage.setItem("modalHideUntil", tomorrow.toISOString());
    setOpen(false);
  };

  const handleHideForever = () => {
    localStorage.setItem("modalHideForever", "true");
    setOpen(false);
  };

  useEffect(() => {
    const hideUntil = localStorage.getItem("modalHideUntil");
    const hideForever = localStorage.getItem("modalHideForever");

    if (hideForever === "true") {
      setOpen(false);
      return;
    }

    if (hideUntil) {
      const hideUntilDate = new Date(hideUntil);
      if (new Date() < hideUntilDate) {
        setOpen(false);
      }
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight="bold">
          과제 업무란?
        </Typography>
        <Typography variant="body2">
          1. 스스로 할 일에 대한 정의를 내리고, 일의 마무리(목적)를 분명히 하여
          일을 완성해 나가는 업무
        </Typography>
        <Typography variant="body2">
          2. 일을 제대로 하는 방법을 배우고, 한 분야의 전문가로 성장하기 위한
          업무
        </Typography>
        <Typography variant="body2">
          3. 성공 경험을 토대로, 삶의 주제를 찾게 해주는 업무
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AsmtStatus
          year={year}
          onChangeYear={changeYear}
          setState={setState}
        ></AsmtStatus>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <PartStatus year={year}></PartStatus>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <AsmtTable
          main={true}
          state={state}
          setState={setState}
          setYear={setYear}
          year={year}
        ></AsmtTable>
      </Grid>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        slots={{
          backdrop: "div", // 백드롭을 사용자 정의하거나 제거
        }}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent" }, // 백드롭을 투명하게 설정
          },
        }}
      >
        <Box sx={style}>
          <Grid container justifyContent="space-between" alignItems="center">
            <CustomText type="title">과제 업무 안내</CustomText>

            <CloseIcon
              onClick={() => setOpen(false)}
              sx={{ fontSize: 30 }}
              style={{ cursor: "pointer" }}
            />
          </Grid>

          <Typography sx={{ mt: 4, fontWeight: 600 }}>
            25년부터 신규 과제 등록은 별도 사이트를 통해 진행 예정입니다.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            각 그룹장님과 논의 후 과제 진행이 확정 된 과제에 한하여,
          </Typography>
          <Typography sx={{ mt: 2 }}>
            신규 등록이 필요한 경우에는 사이트 제작 전까지는
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <span style={{ fontWeight: 600 }}>정아람K에게 메일 주시면</span>{" "}
            기획조정실과 논의 후 회신드리겠습니다.
          </Typography>
          <Typography sx={{ mt: 4 }}>
            ※ 인턴과제의 경우 정상적으로 진행해주시면 됩니다.
          </Typography>
          <Grid container justifyContent="right" mt="30px">
            <Typography
              onClick={handleHideForDay}
              sx={linkStyle}
              marginRight="12px"
            >
              오늘 하루 보지 않기
            </Typography>
            <Typography onClick={handleHideForever} sx={linkStyle}>
              다시 보지 않기
            </Typography>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}

export default MainPage;

const style = {
  position: "absolute" as "absolute",
  top: "30%",
  right: "-5%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const linkStyle = {
  textDecoration: "underline",
  cursor: "pointer",
  color: "#666",
  "&:hover": {
    color: "#000",
  },
};
